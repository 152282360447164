<template>
  <div class="order">
    <Header :order="order.Номер" :date="order.Дата" isOrder />

    <div class="wrapper">
      <div class="order__info">
        <div class="container">
          <ul class="order__info-list">
            <li class="order__info-item order__info-item--timer" 
                v-if="!order.ЗаказВыдан"
              >
                <span 
                  class="order__info-value timer" 
                  :class="{ 'warning': warning, 'danger': danger }"
                >
                  <span v-if="danger">-</span>{{ timer.hours }}<span class="colon">:</span>{{ timer.mins }}<span class="colon">:</span>{{ timer.sec }}
                </span>
            </li>
            <li class="order__info-item order__info-item--nested">
              <div class="order__info-inner">
                <span class="order__info-header">№ заказа</span>
                <span class="order__info-value">{{ order.Номер | number }}</span>
              </div>
              <div class="order__info-inner">
                <span class="order__info-header">Время</span>
                <span class="order__info-value">{{ order.ВремяДоставки | time }}</span>
              </div>
              <div class="order__info-inner">
                <span class="order__info-header">Телефон</span>
                <a class="order__info-value" :href="'tel:' + order.ТелефонСсылка">{{ order.Телефон }}</a>
              </div>
            </li>
            <li class="order__info-item order__info-item--nested">
              <div class="order__info-inner">
                <span class="order__info-header">Клиент</span>
                <span class="order__info-value">{{ order.Клиент }}</span>
              </div>
              <div class="order__info-inner">
                <span class="order__info-header">Оплата</span>
                <span class="order__info-value">{{ order.ВидОплаты }}</span>
              </div>
            </li>
            <li class="order__info-item">
              <div class="order__info-inner">
                <span class="order__info-header">Адрес</span>
                <!-- <a class="order__info-value" :href="'https://2gis.ru/search/' + order.АдресДоставки | address" target="_blank">{{ order.АдресДоставки }}</a> -->
                <a class="order__info-value order__info-value--map" :href="'https://yandex.ru/maps/46/kirov/?ll=49.667978%2C58.603581&z=15&text=' + order.АдресДоставкиСсылка" target="_blank">{{ order.АдресДоставки }}</a>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="order__detail" v-if="!isLoading">
        <div class="container">
          <ul>
            <li class="order__detail-item">
              <span class="order__detail-gray">Номенклатура</span>
              <span class="order__detail-gray order__detail-right">Кол-во</span>
            </li>
            <li class="order__detail-item" v-for="(dish, dishIndex) in order.Товары" :key="dishIndex">
              <p class="order__detail-left">{{ dish.Номенклатура }}</p>
              <p class="order__detail-right order__detail-count">
                <span>{{ dish.Количество }}</span>
              </p>
            </li>            
            <li class="order__detail-overall">
              <p class="order__detail-gray order__detail-sum">Сумма</p>
              <p class="order__detail-right"><b>{{ order.Сумма }}</b></p>
            </li>
          </ul>  
        </div>  
      </div>
      <Loader v-else />

      <div class="payment">
        <div class="container">
          <div class="payment__button">
            <button class="payment__submit" @click="submitOrder" :disabled="order.ЗаказВыдан">
              <span v-if="!order.ЗаказВыдан">Выдать заказ</span>
              <span v-else>Заказ выдан</span>
            </button>

            <button 
              class="certificate__submit" 
              @click="openCertificatePopup" 
              :disabled="!order.ЗаказВыдан ? true : order.СертификатЗапрошен"
            >
              <span>Сертификат за опоздание</span>
            </button>
          </div>   
        </div>
      </div>    
    </div>

    <popup 
      :is-opened="openPopup" 
      :title="popupTitle" 
      :text="popupText" 
      :question="question"
      @close-popup="openPopup = false" 
      @handle-question="submitCertificate"
    />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Popup from '@/components/Popup.vue'
import moment from 'moment'
import Vue from 'vue'
import Loader from '@/components/Loader.vue'

Vue.prototype.moment = moment

export default {
  name: 'Order',

  components: {
    Header,
    Popup,
    Loader
  },

  data () {
    return {
      warning: false,
      danger: false,
      currentTime: '',
      deliveryTime: '',
      timer: {
        hours: '--',
        mins: '--',
        sec: '--'
      },
      fields: {},
      openPopup: false,
			popupTitle: '',
			popupText: '',
      question: '',
      isLoading: false,
      certificateButtonActive: false,
    }
  },

  computed: {
    order () {
      return this.$store.state.order ? this.$store.state.order : {}
    }
  },

  filters: {
    time: value => {
      return !value ? '' : value.split('T')[1].slice(0, -3)
    },

    number: value => {
      return !value ? '' : value.split('-')[1]
    }
  },

  created () {
    this.$root.$on('popup', this.popupHandler)
    this.startTimer()
  },

  methods: {
    popupHandler ({ title,text, question }) {
			this.popupTitle = title
			this.popupText = text
      this.question = question
			this.openPopup = true
    },

    startTimer () {
      setInterval(() => {
        this.currentTime = moment()
        this.deliveryTime = this.order.ВремяДоставки ? moment(this.order.ВремяДоставки): this.currentTime 
        let seconds = this.deliveryTime.diff(this.currentTime, 'seconds') 
        
        if (seconds < 0) {
          this.warning = false
          this.danger = true
        }

        let hours = Math.trunc(seconds / 3600)

        if (hours < 0) {
          hours = Math.abs(hours)
        }
        if (hours < 10) {
          hours = '0' + hours
        }

        let minutes = Math.trunc(seconds / 60)

        if (minutes < 10 && minutes >= 0) {
          this.warning = true
        }
        if (minutes < 0) {
          minutes = Math.abs(minutes)
        } 
       
        let mins = minutes % 60;
        if (mins < 10) {
          mins = '0' + mins
        }

        let sec = Math.abs(seconds) - (minutes * 60)

        if (sec < 10) {
          sec = '0' + sec
        }

        this.timer.hours = hours
        this.timer.mins = mins
        this.timer.sec = sec
      }, 1000)
    },

    stopTimer () {
      clearTimeout(this.timer)
    },

    submitOrder () {
      this.isLoading = true

      // check geo
      if (this.order.ПроверятьГеолокацию) {
        if (!navigator.geolocation) {
          this.isLoading = false
          this.$root.$emit('popup', { title: 'Ошибка', text: 'Геолокация не поддерживается вашим браузером' })
          return false
        } else {
          navigator.geolocation.getCurrentPosition(this.successClientGeo, this.errorClientGeo);
        }
      } else {
        this.processOrder()
      }      
    },

    openCertificatePopup () {
      this.$root.$emit('popup', { text: 'Запросить сертификат за опоздание?', question: 'Выдать'})
    },

    submitCertificate() {
      this.fields = {
        sessionId: localStorage.getItem('sessionId'),
        orderId: this.$store.state.order_id,
      }

      this.$store.dispatch('ORDER_DELAY', this.fields)
        .then(
          (response) => {
            this.isLoading = false
            if (response.status === 200) {
              this.$router.push('/orders')
            } else if (response.status === 400 || response.data.КодОшибки === 400) {
              this.$root.$emit('popup', { title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }
          },
          (err) => {
            this.isLoading = false
            this.$root.$emit('popup', { title: 'Ошибка', text: err.response.data.ОписаниеОшибки ? err.response.data.ОписаниеОшибки : 'Ошибка загрузки' })
            throw new Error(err)
          }
        )
        .catch(err => {
          this.isLoading = false
          this.$root.$emit('popup', { title: 'Ошибка', text: err.response.data.ОписаниеОшибки ? err.response.data.ОписаниеОшибки : 'Ошибка загрузки' })
        })
    },

    processOrder (coords) {
      this.fields = {
        sessionId: localStorage.getItem('sessionId'),
        orderId: this.$store.state.order_id,
        coords: coords ? JSON.stringify(coords) : "Не требуется",
      }

      this.$store.dispatch('ORDER_DONE', this.fields)
        .then(
          (response) => {
            this.isLoading = false
            if (response.status === 200) {
              this.$router.push('/orders')
            } else if (response.status === 400 || response.data.КодОшибки === 400) {
              this.$root.$emit('popup', { title: 'Ошибка', text: response.data.ОписаниеОшибки })
            }           
          },
          (err) => {
            this.isLoading = false
            this.$root.$emit('popup', { title: 'Ошибка', text: err.response.data.ОписаниеОшибки ? err.response.data.ОписаниеОшибки : 'Ошибка загрузки' })
            throw new Error(err)
          }
        )
        .catch(err => {
          this.isLoading = false
          this.$root.$emit('popup', { title: 'Ошибка', text: err.response.data.ОписаниеОшибки ? err.response.data.ОписаниеОшибки : 'Ошибка загрузки' })
        })
    },

    successClientGeo (position) {
      const coords = [position.coords.latitude, position.coords.longitude]
      this.processOrder(coords)
    },

    errorClientGeo () {
      this.isLoading = false
      this.$root.$emit('popup', { title: 'Ошибка', text: 'Невозможно получить ваше местоположение' })
      return false
    }    
  },

  destroyed () {
    this.stopTimer()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .header__info {
  font-size: 16px;
  white-space: nowrap;
}
.wrapper {
  width: 100%;
  margin: 0px auto;
  background-color: #fff;
}
.container {
  margin: 0 10px;
}
.order {
  min-height: 100vh;
  background-color: #f2f2f2;
  &__info {
    background-color: #f2f2f2;
    &-item {
      padding: 12px 10px;
      border-bottom: 1px solid #cacaca;
      &--nested {
        display: flex;
        justify-content: space-between;
        .order__info-inner + .order__info-inner {
          margin-left: 5px;
        }
      }
      &--timer {
        text-align: center;
      }
    }
    &-inner {
      display: flex;
      flex-direction: column;
    }
    &-header {
      color: #cacaca;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 4px;
    }
    &-value {
      font-size: 14px;
      line-height: 16px;
      &--map {
        text-decoration: underline;
        text-decoration-color: rgb(170, 170, 170);
      }
      &.timer {
        color: #28a745; 
        text-align: center;
        font-size: 24px;
        &.danger {
          color: #dc3545;
        }
        &.warning {
          color: #ffc107;
        }
      }     
      .colon {
        animation: blink 1s forwards linear infinite;
      }
    }
  }
  &__detail {
    background-color: #fff;
    font-size: 14px;
    &-item {
      padding: 12px 10px 10px;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-of-type {
        border-bottom: 1px solid #000;
      }  
    }
    &-right {
      width: 60px;
    }
    &-left {
      width: calc(100% - 70px);
      margin-right: 10px;
    }
    &-count {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-gray {
      color: #7e7e7e;
    }
    &-overall {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 10px;
      border-top: 1px solid #000;   
      position: relative;
      top: -1px;   
    }
    &-sum {
      text-align: right;
      width: calc(100% - 86px);
      margin-right: 26px;
    }
  }
}
.payment {
  background-color: #f2f2f2;
  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  &__item {
    width: calc(50% - 10px);
    &-check {
      margin-bottom: 16px;
      margin-left: 10px;
      white-space: nowrap;
    }
    &-head {
      padding: 14px 10px 13px;
      color: #7e7e7e;
      margin-bottom: 17px;
      font-size: 14px;
      border-bottom: 1px solid #cacaca;
    }
  }
  &__email {
    margin: 0 10px 24px;
  }
  &__button {
    margin: 0 10px;
    padding-bottom: 25px;
  }
  &__submit {
    color: #000;
    font-family: $font-base;
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    padding: 14px 0 13px;
    border-radius: 50px;
    background-color: $main;
    cursor: pointer;
    margin-top: 24px;
    &:active {
      transform: scale(0.98);
    }
  }
}

.certificate {
  &__submit {
    color: #000;
    font-family: $font-base;
    font-size: 18px;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    padding: 14px 0 13px;
    border-radius: 50px;
    background-color: $main;
    cursor: pointer;
    margin-top: 24px;

    &:active {
      transform: scale(0.98);
    }
  }
}
.email__input {
  background-color: #fff;
  width: 100%;
  padding: 12px 20px 14px;
  margin: 0;
  outline: none;
  border: 1px solid #cacaca;
  border-radius: 50px;
  color: #000;
  font-size: 16px;
  font-family: $font-base;
}
.error-input {
  box-shadow: 0px 0px 10px $red;
}
.error-radio::before {
  box-shadow: 0px 0px 7px $red;
}
@keyframes blink {
  0% {
    opacity: 0 
  }
  50% {
    opacity: 1 
  }
  100% {
    opacity: 0 
  }
}
</style>
